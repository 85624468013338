import styled from "styled-components";
import { media } from "../../theme/globalStyle";

const HowToGetStyles = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  & .content {
    display: flex;
    flex-direction: column;
    & h3 {
      font-size: 1.25rem;
      font-weight: 900;
      padding-bottom: 2rem;
    }
    & ul {
      & li {
        & span {
          display: inline-block;
          padding-right: 1rem;
          font-weight: 700;
        }
      }
      & li a:hover {
        color: var(--blue);
      }
    }

    & .parking {
      padding-top: 3rem;
    }
  }

  & .map {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 3rem;
  }
`;

export default HowToGetStyles;
