import styled from "styled-components";

const HeaderBgImageStyles = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  width: 100%;
  height: 45vh;
  background-color: var(--blackish);
  ${(props) =>
    props.withShadow &&
    "box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;"}

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & .title-wrapper {
    position: absolute;
    bottom: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
  }
`;

export default HeaderBgImageStyles;
