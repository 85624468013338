import styled from "styled-components";
import { media } from "../../theme/globalStyle";

const PaymentsStyles = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & ul.rates {
    padding-top: 2rem;
    & p {
      font-weight: 700;
      padding-bottom: 1.5rem;
    }
    & li {
      display: flex;
      align-items: baseline;
      padding-bottom: 1rem;
      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.5rem;
        min-height: 1.5rem;
        margin-right: 0.75rem;
        background-color: var(--blue);
        font-size: 0.75rem;
        color: var(--whiteish);
        font-weight: 700;
        border-radius: 50%;
      }
    }
  }

  & ul.documents {
    padding-top: 2rem;

    & li {
      padding-bottom: 4rem;
      & p {
        font-weight: 700;
      }
      & span {
        display: block;
        padding-top: 1.25rem;
        padding-bottom: 0.5rem;
        text-transform: uppercase;
        font-style: italic;
        color: var(--blue);
      }
    }
  }

  ${media.md`
    & ul.rates, & ul.documents {
      padding-top: 3rem;
    }
  `}
`;

export default PaymentsStyles;
