import styled from "styled-components";

const ServicesStyles = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  & ul.services {
    padding-top: 2rem;
    & p {
      font-weight: 700;
      padding-bottom: 1.5rem;
    }
    & li {
      display: flex;
      align-items: baseline;
      padding-bottom: 1rem;
      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.5rem;
        min-height: 1.5rem;
        margin-right: 0.75rem;
        background-color: var(--blue);
        font-size: 0.75rem;
        color: var(--whiteish);
        font-weight: 700;
        border-radius: 50%;
      }
    }
  }
`;

export default ServicesStyles;
