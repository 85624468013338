import React from "react";
import { NavLink } from "react-router-dom";
import BrandName from "../common/brandName/BrandName";
import Container from "../common/styledComponents/Container";
import FooterStyles from "./FooterStyles";

const Footer = () => {
  return (
    <FooterStyles>
      <Container>
        <div className="footer-content">
          <div className="footer-brand">
            <BrandName small />
          </div>
          <div className="footer-right">
            <ul className="footer-contact">
              <li>
                <img src="./assets/icons/address.svg" alt="address icon" />
                <p>
                  ul. Krochmalna 56 lok. U8
                  <br />
                  00-864 Warszawa
                </p>
              </li>
              <li>
                <img src="./assets/icons/phone.svg" alt="phone icon" />
                <p>22 364 44 90</p>
              </li>
              <li>
                <img src="./assets/icons/email.svg" alt="email icon" />
                <a href="mailto:kancelaria@nurkowski.pl">
                  kancelaria@nurkowski.pl
                </a>
              </li>
            </ul>
            <ul className="footer-menu">
              <li>
                <NavLink to="/okancelarii">Kancelaria</NavLink>
              </li>
              <li>
                <NavLink to="/czynnosci">Czynności</NavLink>
              </li>
              <li>
                <NavLink to="/oplaty">Opłaty</NavLink>
              </li>

              <li>
                <NavLink to="/kontakt">Kontakt</NavLink>
              </li>
              <li>
                <NavLink to="/dojazd">Jak dojechać</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </FooterStyles>
  );
};

export default Footer;
