import React from "react";
import PropTypes from "prop-types";
import Container from "../common/styledComponents/Container";
import Footer from "../footer/Footer";
import PaymentsStyles from "./PaymentsStyles";
import Button from "../common/button/Button";
import SectionContent from "../common/styledComponents/SectionContent";
import Title from "../common/styledComponents/Title";
import HeaderBgImage from "../common/headerBgImage/HeaderBgImage";

const Payments = ({ paymentsData }) => {
  return (
    <>
      <PaymentsStyles>
        <HeaderBgImage
          imgSrcset={paymentsData.imgSrcset}
          imgFallback={paymentsData.imgFallback}
          withShadow
          withTitle
        >
          <Title withDecoration light>
            opłaty
          </Title>
        </HeaderBgImage>
        <Container>
          <SectionContent>
            <h3>{paymentsData.sectionTitle}</h3>
            <ul className="rates">
              <p>{paymentsData.ratesTitle}</p>
              {paymentsData.rates.map((rate, index) => (
                <li key={index}>
                  <span>{index + 1}</span>
                  {rate.rate}
                </li>
              ))}
            </ul>
            <ul className="documents">
              {paymentsData.lawDocuments.map((document, index) => (
                <li key={index}>
                  <p>{document.descr}</p>
                  {document.files.map((file) => (
                    <>
                      <span>{file.fileName}</span>
                      <Button
                        small
                        color="var(--grey)"
                        bgColor="var(--mid-grey)"
                        hoverBg="var(--dark-blue)"
                      >
                        <a href={file.fileURL} download>
                          pobierz plik
                        </a>
                      </Button>
                    </>
                  ))}
                </li>
              ))}
            </ul>
          </SectionContent>
        </Container>
      </PaymentsStyles>
      <Footer />
    </>
  );
};

Payments.propTypes = {
  paymentsData: PropTypes.object.isRequired,
};

export default Payments;
