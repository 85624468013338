import styled from "styled-components";

const ButtonStyles = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
  border: 1px solid transparent;
  outline: 1px solid transparent;
  background-color: ${(props) => props.bgColor};
  width: 150px;
  height: ${(props) => (props.small ? "36px" : "44px")};
  letter-spacing: 1px;
  transition: 0.3s background-color;
  font-size: ${(props) => (props.small ? "0.75rem" : "0.875rem")};
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${(props) => props.hoverBg};
    color: var(--whiteish);
  }

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    line-height: 36px;
    cursor: pointer;
  }
  &[disabled] {
    cursor: default;
    background-color: var(--grey);
  }
`;

export default ButtonStyles;
