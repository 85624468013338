import React, { useEffect, useState } from "react";
import { GlobalStyle } from "./theme/globalStyle";
import data from "./data/data.json";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppContext from "./context/AppContext";
import Spinner from "./components/common/Spinner";
import Menu from "./components/menu/Menu";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Payments from "./components/payments/Payments";
import Contact from "./components/contact/Contact";
import HowToGet from "./components/howtoget/HowToGet";
import { throttle } from "lodash";
import ScrollToTop from "./helpers/scrollToTop";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [appData, setAppData] = useState(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : null
  );

  const [scrollPos, setScrollPos] = useState(
    typeof window !== "undefined" ? window.scrollY : null
  );

  const listenToScroll = () => {
    const position = window.scrollY;
    setScrollPos(position);
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    setLoading(false);
    setAppData(data);
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", throttle(listenToScroll, 150), {
      passive: true,
    });
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  return (
    <>
      {loading && !appData ? (
        <Spinner size={10} margin={5} />
      ) : (
        <AppContext.Provider
          value={{
            isNavVisible,
            setIsNavVisible,
            screenWidth,
            scrollPos,
            setScrollPos,
          }}
        >
          <GlobalStyle />
          <Router>
            <ScrollToTop />
            <header>
              <Menu nav={appData.nav} />
            </header>
            <Switch>
              <Route path="/okancelarii">
                <About aboutData={appData.about} />
              </Route>
              <Route path="/czynnosci">
                <Services servicesData={appData.services} />
              </Route>
              <Route path="/oplaty">
                <Payments paymentsData={appData.payments} />
              </Route>
              <Route path="/kontakt">
                <Contact contactData={appData.contact} />
              </Route>
              <Route path="/dojazd">
                <HowToGet howtogetData={appData.howtoget} />
              </Route>
              <Route path="/">
                <Home homeData={appData.home} />
              </Route>
            </Switch>
          </Router>
        </AppContext.Provider>
      )}
    </>
  );
};

export default App;
