import styled from "styled-components";
import { media } from "../../../theme/globalStyle";

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0;

  ${media.sm`
    padding: 6rem 0;
  `}
  ${media.md`
    padding: 8rem 0;
    flex-direction: ${(props) => (props.row ? "row" : "column")};
    justify-content: ${(props) =>
      props.spaceBetween ? "space-between" : "flex-start"};
  `}

  ${media.xl`
    padding: 10rem 0;
  `}
`;

export default SectionContent;
