import React from "react";
import { bool, object, string } from "prop-types";
import HeaderBgImageStyles from "./HeaderBgImageStyles";

const HeaderBgImage = ({
  imgSrcset,
  imgFallback,
  withShadow,
  withTitle,
  children,
}) => {
  return (
    <HeaderBgImageStyles withShadow={withShadow}>
      <img
        src={imgFallback}
        srcSet={imgSrcset}
        alt="section header background"
      />
      {withTitle && <div className="title-wrapper">{children}</div>}
    </HeaderBgImageStyles>
  );
};

HeaderBgImage.propTypes = {
  imgSrcset: string.isRequired,
  imgFallback: string.isRequired,
  withShadow: bool,
  withTitle: bool,
  title: string,
  children: object
};

HeaderBgImage.defaultProps = {
  withShadow: false,
  withTitle: false,
  title: null,
  children: null
};

export default HeaderBgImage;
