import React, { useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeStyles.js";
import Slider from "react-slick";
import HomeStyles from "./HomeStyles.js";
import AppContext from "../../context/AppContext";
import TextWrapper from "../common/styledComponents/TextWrapper.js";
import Button from "../common/button/Button";
import Title from "../common/styledComponents/Title.js";
import Text from "../common/styledComponents/Text.js";
import Footer from "../footer/Footer.js";
import { NavLink } from "react-router-dom";
import Container from "../common/styledComponents/Container.js";
import SectionContent from "../common/styledComponents/SectionContent.js";
import Slide from "./Slide";

const Home = ({ homeData }) => {
  const { screenWidth } = useContext(AppContext);
  const { imgSM, imgMD, imgLG, imgXL } = homeData.services;

  const slick = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    easing: "ease-in-out",
  };

  return (
    <HomeStyles imgsm={imgSM} imgmd={imgMD} imglg={imgLG} imgxl={imgXL}>
      <Slider {...slick} className="box-shadow">
        {homeData.slides.map((slide) => {
          const { imgSM, imgMD, imgLG, imgXL } = slide.images;
          return (
            <Slide
              key={slide.title}
              title={slide.title}
              subtitle={slide.subtitle}
              imgsm={imgSM}
              imgmd={imgMD}
              imglg={imgLG}
              imgxl={imgXL}
            />
          );
        })}
      </Slider>
      <Container>
        <SectionContent>
          <TextWrapper>
            <Title withDecoration marginBottom>
              kancelaria
            </Title>
            <Text>{homeData.officeDetails}</Text>
          </TextWrapper>
        </SectionContent>
      </Container>

      <div className="home-services">
        <div className="overlay" />
        <Container>
          <div className="content">
            <div className="title-wrapper">
              <Title light marginBottom>
                zakres usług
              </Title>
              {screenWidth >= 992 && (
                <Button color="var(--whiteish)" bgColor="var(--dark-blue)">
                  <NavLink to="/czynnosci">Sprawdź</NavLink>
                </Button>
              )}
            </div>
            <ul>
              <li>
                <span />
                sporządzanie aktów notarialnych
              </li>
              <li>
                <span />
                sporządzanie poświadczeń
              </li>
              <li>
                <span />
                doręczanie oświadczeń
              </li>
              <li>
                <span />
                spisywanie protokołów
              </li>
              <li>
                <span />
                sporządzanie wypisów, odpisów i wyciągów dokumentów
              </li>
            </ul>
            {screenWidth < 992 && (
              <Button color="var(--whiteish)" bgColor="var(--dark-blue)">
                <NavLink to="/czynnosci">Sprawdź</NavLink>
              </Button>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </HomeStyles>
  );
};

export default Home;
