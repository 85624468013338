import { createGlobalStyle, css } from "styled-components";

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
};

const GlobalStyle = createGlobalStyle`

:root {
--whiteish: #e6e6e6;
--blackish:#070507;
--lightgrey: #f1f1f1;
--mid-grey: #d4d4d4;
--grey: #444444;
--pale-blue: #93c6da;
--blue: #14abe7;
--dark-blue: #0f6c91;
--red: #f00f53;

--dark-text: ##393939;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 100%;
}

body {
  width: 100%;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
}

img {
  display: block;
  width: 100%;
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a, a:link, a:hover, a:visited {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  }

button,
input,
select,
textarea {
  font-size: 100%; 
  margin: 0; 
  vertical-align: baseline; 
}

button, html [type=button] {
    -webkit-appearance: button;
    background-color: transparent;
}

.title{
  display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 0.0625rem;
    padding-bottom: 2rem;
    &--blue {
    color: #5ab3c6;
}
}

.subtitle {
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-align: center;
    margin: 0 auto;
    padding: 0 2rem 10rem;
    font-style: italic;
}

.paddings {
    padding: 6rem 0;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 1200px) {max-width: 1140px;}
  }

  .box-shadow {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
`;

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export { GlobalStyle, media };
