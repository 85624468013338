import React from "react";
import { string, func, oneOfType, object, bool } from "prop-types";
import ButtonStyles from "./ButtonStyles";

const Button = ({ children, clickHandler, small, color, bgColor, hoverBg, disabled}) => {
  return (
    <ButtonStyles
      onClick={clickHandler}
      small={small}
      color={color}
      bgColor={bgColor}
      hoverBg={hoverBg}
      disabled={disabled}
    >
      {children}
    </ButtonStyles>
  );
};

Button.propTypes = {
  children: oneOfType([string, object]).isRequired,
  clickHandler: func,
  small: bool,
  color: string,
  bgColor: string,
  hoverBg: string,
  disabled: bool
};

Button.defaultProps = {
  clickHandler: () => {},
  color: "#ffffff",
  bgColor: "var(--blue)",
  hoverBg: "var(--dark-blue)",
  disabled: false
};

export default Button;
