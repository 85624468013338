import styled from "styled-components";
import { media } from "../../../theme/globalStyle";

const Text = styled.p`
  font-size: 1.0625rem;
  line-height: 1.7;
  font-weight: 500;
  text-align: center;
  padding-bottom: 1rem;
  letter-spacing: 1px;
  ${media.md`
    font-size: 1.125rem;
  `}
`;

export default Text;
