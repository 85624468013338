import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const Spinner = ({ size, margin }) => (
  <div data-testid="spinner">
    <PulseLoader size={size} margin={margin} />
  </div>
);

export default Spinner;
