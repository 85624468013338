import styled from "styled-components";
import { media } from "../../../theme/globalStyle";

const BrandNameStyles = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 900;
  text-transform: uppercase;
  color: ${(props) => props.color};
  letter-spacing: 2px;
  cursor: pointer;

  ${media.md`
      font-size: ${(props) => !props.small && "1rem"};
  `}
`;

export default BrandNameStyles;
