import styled, { keyframes } from "styled-components";
import { media } from "../../theme/globalStyle";

const slideLinks = keyframes`
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
`;

const MenuStyles = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100px;
  padding: 1rem;
  background-color: ${(props) =>
    props.bg ? "rgba(0,0,0,0.9)" : "transparent"};
  z-index: 5;
  transition: background-color 0.2s ease-in-out;

  & .brand-name-wrapper {
    z-index: 10;
  }

  ${media.md`
    padding: 1rem 1.5rem;
  `}
  ${media.lg`
    padding: 1.5rem 3rem;
  `}

  &__logo {
    display: inline-block;
  }
`;

export const BurgerButton = styled.button`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 10;
  & span {
    display: block;
    background-color: var(--whiteish);
    width: 26px;
    height: 2px;
    border-radius: 2px;
    text-shadow: none;
    color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 2px;
      background-color: inherit;
      position: absolute;
      left: 0;
      transition-duration: 0.2s, 0.2s;
      transition-delay: 0.2s, 0s;
    }
    &:before {
      top: -10px;
      transition-property: top, transform;
    }
    &:after {
      bottom: -10px;
      transition-property: bottom, transform;
    }
  }
  &.close {
    & span {
      background-color: transparent;
      &:before,
      &:after {
        background-color: var(--blue);
        transition-delay: 0s, 0.2s;
      }
      &:before {
        top: 0;
        transform: rotate(45deg);
      }
      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
  ${media.lg`display: none;`}
`;

export const MobileNav = styled.ul`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--blackish);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  overflow-y: scroll;
  & li {
    position: relative;
    width: fit-content;
    margin-bottom: 1rem;
    color: var(--whiteish);
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.5, 0.25, 0, 1);
    & a {
      display: inline-block;
      width: 100%;
      text-transform: capitalize;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.3;
      cursor: pointer;
      @media screen and (min-width: 375px) {
        font-size: 1.75rem;
      }
      @media screen and (min-width: 490px) {
        font-size: 1.875rem;
      }
      ${media.sm`font-size: 2rem;`}
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--pale-blue);
        width: 0;
        height: 1px;
        opacity: 0;
        transition: 0.3s cubic-bezier(0.5, 0.25, 0, 1);
      }
      &:hover:not(.active) {
        color: var(--pale-blue);
        &:after {
          opacity: 1;
          width: 100%;
        }
      }

      &.active {
        color: var(--blue);
        cursor: default;
      }
    }

    &.slide-link-in {
      animation-name: ${slideLinks};
      animation-delay: 0.3s;
      &:nth-child(2) {
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        animation-delay: 0.5s;
      }
      &:nth-child(4) {
        animation-delay: 0.6s;
      }
      &:nth-child(5) {
        animation-delay: 0.7s;
      }
      &:nth-child(6) {
        animation-delay: 0.8s;
      }
    }
  }
  &.nav-in {
    transform: translate3d(0, 0, 0);
    overflow-y: scroll;
  }
  &.nav-out {
    transform: translate3d(0, -100vh, 0);
  }
`;

export const DesktopNav = styled.ul`
  position: relative;
  display: flex;
  & li {
    position: relative;
    color: var(--whiteish);
    cursor: pointer;
    margin-right: 1.25rem;
    &:last-child {
      margin-right: 0;
    }
    & a {
      display: inline-block;
      text-transform: capitalize;
      font-size: 1.25rem;
      font-weight: 700;

      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--pale-blue);
        width: 0;
        height: 1px;
        opacity: 0;
        transition: 0.3s cubic-bezier(0.5, 0.25, 0, 1);
      }
      &:hover:not(.active) {
        color: var(--pale-blue);
        &:after {
          opacity: 1;
          width: 100%;
        }
      }
      &.active {
        color: var(--blue);
        cursor: default;
      }
    }
  }
`;

export default MenuStyles;
