import React from "react";
import { object } from "prop-types";
import HeaderBgImage from "../common/headerBgImage/HeaderBgImage";
import Container from "../common/styledComponents/Container";
import SectionContent from "../common/styledComponents/SectionContent";
import Title from "../common/styledComponents/Title";
import Footer from "../footer/Footer";
import ContactStyles from "./ContactStyles";
import Form from "./Form/Form";

const Contact = ({ contactData }) => {
  return (
    <>
      <ContactStyles>
        <HeaderBgImage
          imgSrcset={contactData.imgSrcset}
          imgSizes={contactData.imgSizes}
          imgFallback={contactData.imgFallback}
          withShadow
          withTitle
        >
          <Title withDecoration light>
            kontakt
          </Title>
        </HeaderBgImage>
        <Container>
          <SectionContent>
            <div className="content">
              <div className="details">
                <h3>Dane kontaktowe</h3>
                <ul>
                  <li>
                    ul. Krochmalna 56 lok. U8 (wejście bezpośrednio od ulicy na
                    poziomie parteru)
                    <br />
                    00-864 Warszawa
                  </li>
                  <li>tel: 22 364 44 90</li>
                  <li>
                    <a href="mailto:kancelaria@nurkowski.pl">
                      kancelaria@nurkowski.pl
                    </a>
                  </li>
                  <li>
                    Kancelaria czynna jest od poniedziałku do piątku i przyjmuje
                    interesantów w godzinach 8:30 – 17:00, przy czym termin i
                    godzinę podpisania aktu notarialnego należy ustalić z
                    wyprzedzeniem (telefonicznie, mailowo lub osobiście).
                  </li>
                </ul>
              </div>
              <Form />
            </div>
          </SectionContent>
        </Container>
      </ContactStyles>
      <Footer />
    </>
  );
};

Contact.propTypes = {
  contactData: object.isRequired,
};

export default Contact;
