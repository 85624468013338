import styled from "styled-components";
import { media } from "../../theme/globalStyle";

const AboutStyles = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & .top-bg-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./assets/about_bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 40vh;
  }

  & .team {
    display: flex;
    flex-direction: column;
    color: #fff;
    background-image: linear-gradient(
      to top,
      #27667f,
      #2a7a9a,
      #2c8fb5,
      #2da4d2,
      #2fb9ef
    );
    padding: 5rem 0;
    & ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
      & li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 4rem;
        &:last-child {
          padding-bottom: 0;
        }
        & span {
          display: flex;
          justify-content: center;
          width: 200px;
          height: 200px;
          border: 2px solid var(--blue);
          border-radius: 50%;
          background-color: var(--mid-grey);
          overflow: hidden;
          & img {
            display: block;
            height: 110%;
            width: auto;
          }
        }

        & h3 {
          padding-top: 1.5rem;
          font-weight: 900;
        }

        & p {
          max-width: 340px;
          padding-top: 1rem;
          text-align: center;
        }
      }
      ${media.md`
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        & li {
          padding-bottom: 0;
          max-width: 48%;
        }
      `}
    }

    ${media.lg`
      padding: 6rem 0;
      & ul{
        justify-content: space-around;
        & li {
          max-width: unset;
        }
      }
    `}

    ${media.xl`
      padding: 7rem 0;
    `}
  }
`;

export default AboutStyles;
