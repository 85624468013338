import React from "react";

export default React.createContext({
  isNavVisible: false,
  setIsNavVisible: (bool) => {},
  screenWidth: null,
  setScreenWidth: (width) => {},
  scrollPos: 0,
  setScrollPos: (pos) => {},
});
