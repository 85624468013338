import styled from "styled-components";
import { media } from "../../theme/globalStyle";

const ContactStyles = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & .content {
    display: flex;
    flex-direction: column;

    ${media.md`
      flex-direction: row;
      justify-content: space-between;
    `}
  }

  & .details {
    & h3 {
      font-size: 1.25rem;
      font-weight: 900;
      padding-bottom: 3rem;
    }

    & ul {
      & li {
        padding-bottom: 1.5rem;
      }
      & li a:hover {
        color: var(--blue);
      }
    }

    ${media.md`
      width: 48%;
      max-width: 400px;
    `}
  }
`;

export default ContactStyles;
