import styled from "styled-components";
import { media } from "../../../theme/globalStyle";

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  ${media.sm`max-width: 540px;`}
  ${media.md`max-width: 720px;`}
  ${media.lg`max-width: 900px;`}
  ${media.xl`max-width: 1040px;`}
  ${media.xxl`max-width: 1200px;`}
`;

export default Container;
