import styled from "styled-components";

const Title = styled.h2`
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 1.875rem;
  color: ${(props) => (props.light ? "var(--whiteish)" : "var(--dark-text)")};
  font-weight: 900;
  letter-spacing: 2px;
  padding: ${(props) => props.withDecoration && "1.5rem 0"};
  margin-bottom: ${(props) => (props.marginBottom ? "5rem" : "unset")};
  ${(props) =>
    props.withDecoration &&
    `
    &:before, &:after {
      position: absolute;
      display: block;
      content: "";
      width: 40%;
      height: 0.25rem;
      background-color: var(--blue);
    }
    &:before { top: 0;}
    &:after { bottom: 0; }
    `}
`;

export default Title;
