import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import MenuStyles, { BurgerButton, DesktopNav, MobileNav } from "./MenuStyles";
import { NavLink } from "react-router-dom";

import AppContext from "../../context/AppContext";
import BrandName from "../common/brandName/BrandName";

const Menu = ({ nav }) => {
  const { isNavVisible, setIsNavVisible, screenWidth, scrollPos } =
    useContext(AppContext);
  const [largeScreen, setLargeScreen] = useState(false);
  const [navBg, setNavBg] = useState(false);

  useEffect(() => {
    if (screenWidth >= 992) {
      setIsNavVisible(true);
      setLargeScreen(true);
    } else {
      setIsNavVisible(false);
      setLargeScreen(false);
    }
  }, [screenWidth,setIsNavVisible]);

  useEffect(() => {
    if (scrollPos > 50) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  }, [scrollPos]);

  return (
    <MenuStyles bg={navBg}>
      <div
        className="brand-name-wrapper"
        onClick={() => setIsNavVisible(false)}
      >
        <NavLink to="/" exact>
          <BrandName />
        </NavLink>
      </div>
      <BurgerButton
        onClick={() => setIsNavVisible(!isNavVisible)}
        className={isNavVisible ? "close" : null}
      >
        <span />
      </BurgerButton>
      {!largeScreen && (
        <MobileNav className={isNavVisible ? "nav-in" : "nav-out"}>
          {nav.map((link) => (
            <li
              key={link.page}
              onClick={() => setIsNavVisible(false)}
              className={isNavVisible ? "slide-link-in" : null}
            >
              <NavLink to={link.href} exact>
                {link.page}
              </NavLink>
            </li>
          ))}
        </MobileNav>
      )}
      {largeScreen && (
        <DesktopNav>
          {nav.map((link) => (
            <li key={link.page}>
              <NavLink to={link.href} exact>
                {link.page}
              </NavLink>
            </li>
          ))}
        </DesktopNav>
      )}
    </MenuStyles>
  );
};

Menu.propTypes = {
  nav: PropTypes.array.isRequired,
};

export default Menu;
