import React from "react";
import { object } from "prop-types";
import ServicesStyles from "./ServicesStyles.js";
import Footer from "../footer/Footer";
import Container from "../common/styledComponents/Container.js";
import SectionContent from "../common/styledComponents/SectionContent.js";
import Title from "../common/styledComponents/Title.js";
import HeaderBgImage from "../common/headerBgImage/HeaderBgImage.js";

const Services = ({ servicesData }) => {
  return (
    <>
      <ServicesStyles>
        <HeaderBgImage
          imgSrcset={servicesData.imgSrcset}
          imgFallback={servicesData.imgFallback}
          withShadow
          withTitle
        >
          <Title withDecoration light>
            czynności
          </Title>
        </HeaderBgImage>
        <Container>
          <SectionContent>
            <h3>{servicesData.sectionTitle}</h3>
            <ul className="services">
              <p>{servicesData.servicesTitle}</p>
              {servicesData.servicesList.map((service, index) => (
                <li key={index}>
                  <span>{index + 1}</span>
                  {service}
                </li>
              ))}
            </ul>
          </SectionContent>
        </Container>
      </ServicesStyles>
      <Footer />
    </>
  );
};

Services.propTypes = {
  servicesData: object.isRequired,
};

export default Services;
