import styled from "styled-components";
import { media } from "../../../theme/globalStyle";

const TextWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;

  ${media.md`
    max-width: 600px;
  `}

  ${media.lg`
    max-width: 700px;
  `}

  ${media.xl`
    max-width: 800px;
  `}
`;

export default TextWrapper;
