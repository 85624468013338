import React from "react";
import { object } from "prop-types";
import HeaderBgImage from "../common/headerBgImage/HeaderBgImage";
import Container from "../common/styledComponents/Container";
import SectionContent from "../common/styledComponents/SectionContent";
import Title from "../common/styledComponents/Title";
import Footer from "../footer/Footer";
import HowToGetStyles from "./HowToGetStyles.js";

const HowToGet = ({ howtogetData }) => {
  return (
    <>
      <HowToGetStyles>
        <HeaderBgImage
          imgSrcset={howtogetData.imgSrcset}
          imgSizes={howtogetData.imgSizes}
          imgFallback={howtogetData.imgFallback}
          withShadow
          withTitle
        >
          <Title withDecoration light>
            Jak dojechać
          </Title>
        </HeaderBgImage>
        <Container>
          <SectionContent>
            <div className="content">
              <h3>Dojazd komunikacją miejską</h3>
              <ul>
                <li>
                  <span>Autobusy:</span>przystanki - Wronia 01, Mennica 02 oraz
                  Chłodna.
                </li>
                <li>
                  <span>Tramwaje:</span>przystanek – Muzeum Powstania
                  Warszawskiego.
                </li>
                <li>
                  <span>Metro:</span>najbliższe stacje – Rondo Daszyńskiego i
                  Rondo ONZ.
                </li>
              </ul>
              <div className="parking">
                <h3>Parkowanie</h3>
                <p>
                  Parkowanie samochodów możliwe jest na ogólnodostępnych,
                  płatnych miejscach postojowych wzdłuż ulicy Krochmalnej oraz
                  przy ulicy Chłodnej (pomiędzy ulicami Wronią, a Krochmalną), a
                  także na parkingach podziemnych w budynku przy ul. Haberbuscha
                  i Schielego oraz w budynku przy ul. Krochmalnej 61 (wjazd od
                  ul. Wroniej).
                </p>
              </div>
              <div className="map">
                <h3>Znajdź nas na mapie</h3>
                <a href="https://goo.gl/maps/FFWi4byGEycQn5cq7" target="blank">
                  <img src="./assets/howtoget/map.jpg" alt="Mapa" />
                </a>
              </div>
            </div>
          </SectionContent>
        </Container>
      </HowToGetStyles>
      <Footer />
    </>
  );
};
HowToGet.propTypes = {
  howtogetData: object.isRequired,
};

export default HowToGet;
