import styled from "styled-components";
import { media } from "../../theme/globalStyle";

const SlideStyles = styled.div`
  position: relative;
  width: 100vw;
  height: 75vh;
  float: left;
  background-image: ${(props) => props.imgsm && `url("${props.imgsm}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--blackish);

  & .slide-header {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-52%);
    text-align: center;
    color: var(--whiteish);

    & .slide-title {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 1.5rem;
      font-weight: 900;
      z-index: 10;
      ${media.sm`
        font-size: 1.75rem;
      `}
      ${media.lg`
        font-size: 2.125rem;
      `}
      ${media.xl`
        font-size: 2.25rem;
      `}
    }
    & .slide-subtitle {
      padding-top: 1.25rem;
      font-size: 1rem;
      letter-spacing: 1px;
      line-height: 1.7;
      margin: 0 auto;
      max-width: 80vw;
      ${media.md`
        font-size: 1.125rem;
      `}
      ${media.lg`
        font-size: 1.125rem;
        padding-top: 1.25rem;
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 70vw;
      `}
      ${media.xl`
        font-size: 1.25rem;
        max-width: 60vw;
      `}
    }
  }

  ${media.md`background-image: ${(props) =>
    props.imgmd && `url("${props.imgmd}")`};`}
  ${media.lg`background-image: ${(props) =>
    props.imglg && `url("${props.imglg}")`};`}
    ${media.xl`background-image: ${(props) =>
    props.imgxl && `url("${props.imgxl}")`};`}
`;

export default SlideStyles;
