import styled, { css } from "styled-components";
import { media } from "../../../theme/globalStyle";

const InputWithBorder = (color) => css`
  resize: none;

  & ~ label {
    top: -0.875rem;
    left: 0;
    font-size: 0.875rem;
  }

  & ~ .focus-border {
    &:before,
    &:after {
      width: 100%;
      background-color: ${color};
    }
    & i:before,
    & i:after {
      height: 100%;
      background-color: ${color};
    }
  }
`;

const TextareaWithBorder = (color) => css`
  resize: none;

  & ~ label {
    top: -1.5rem;
    left: 0;
    font-size: 0.875rem;
  }

  & ~ .focus-border {
    &:before,
    &:after {
      width: 100%;
      background-color: ${color};
    }
    & i:before,
    & i:after {
      background-color: ${color};
      height: calc(100% - 6px);
      @-moz-document url-prefix() {
        height: 100%;
      }
      @supports (-ms-ime-align: auto) {
        height: 100%;
      }
    }
  }
`;

const FormStyles = styled.div`
  position: relative;
  padding-top: 3rem;
  & h3 {
    font-size: 1.25rem;
    font-weight: 900;
    padding-bottom: 1.5rem;
  }

  & form {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    z-index: 0;

    & .sent-status {
      display: flex;

      & p {
        margin-bottom: 1rem;
        font-size: 0.9375rem;
        font-weight: 700;
        letter-spacing: 1px;
        &.success {
          color: var(--blue);
        }
        &.error {
          color: var(--red);
        }
      }
    }

    & fieldset {
      position: relative;
      width: 100%;
      margin: 1.5rem 0;
      padding: 0;
      border: none;
      outline: none;
      &:last-of-type {
        margin-top: 0.5rem;
      }
      & .field {
        position: relative;
        width: 100%;

        & input,
        & textarea {
          width: 100%;
          padding: 0.625rem;
          font-size: 1rem;
          font-weight: 600;
          color: var(--blackish);
          letter-spacing: 1px;
          border: 1px solid var(--grey);
          border-radius: 3px;
          outline: 1px solid transparent;
          transition: all 0.3s ease-in;
          background: transparent;
        }

        & label {
          position: absolute;
          left: 14px;
          width: 100%;
          top: 1rem;
          color: var(--grey);
          transition: all 0.3s ease-in;
          z-index: -1;
          letter-spacing: 0.5px;
        }

        & input {
          & ~ label {
            top: 50%;
            transform: translateY(-50%);
          }

          &:focus {
            ${InputWithBorder()}
          }
        }

        & textarea {
          line-height: 1.5rem;
          height: 150px !important;
          overflow-y: hidden;
          white-space: pre-wrap;
          word-wrap: break-word;

          & ~ .focus-border {
            &:after,
            & i:after {
              bottom: 6px;

              @-moz-document url-prefix() {
                bottom: 0;
              }

              @supports (-ms-ime-align: auto) {
                bottom: 0;
              }
            }
          }
          &:focus {
            ${TextareaWithBorder()}
          }
        }

        & .focus-border {
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: var(--blackish);
            transition: all 0.3s;
          }

          &:after {
            top: auto;
            bottom: 0;
            left: auto;
            right: 0;
          }

          & i:before,
          & i:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 0;
            background-color: var(--blackish);
            transition: all 0.4s;
          }

          & i:after {
            left: auto;
            right: 0;
            top: auto;
            bottom: 0;
          }
        }
      }

      & .input-error {
        display: block;
        position: absolute;
        color: var(--red);
        top: -1.5rem;
        right: 0;
        font-size: 0.75rem;
        transition: 0.3s;
        font-weight: 600;
      }

      & .honey-row {
        display: none;
      }

      &.not-empty {
        & input {
          ${InputWithBorder("var(--blackish)")}
        }

        & textarea {
          ${TextareaWithBorder("var(--blackish)")}
        }
      }

      &.no-valid {
        & input {
          ${InputWithBorder("var(--red)")}
        }

        & textarea {
          ${TextareaWithBorder("var(--red)")}
        }
      }

      &.valid {
        & input {
          ${InputWithBorder("var(--dark-blue)")}
        }
        & textarea {
          ${TextareaWithBorder("var(--dark-blue)")}
        }
      }
    }
  }
  ${media.md`
      width: 48%;
      padding-top: 0;
    `}
`;

export default FormStyles;
