import React from "react";
import { string, bool } from "prop-types";
import BrandNameStyles from "./BrandNameStyles";

const BrandName = ({ color, small }) => {
  return (
    <BrandNameStyles color={color} small={small}>
      Kancelaria Notarialna
      <br />
      Krzysztof Nurkowski, 
      <br />
      Paweł Dąbrowa
    </BrandNameStyles>
  );
};

BrandName.propTypes = {
  color: string,
  small: bool,
};

BrandName.defaultProps = {
  color: "var(--whiteish)",
  small: false,
};

export default BrandName;
