import styled from "styled-components";
import { media } from "../../theme/globalStyle";

const FooterStyles = styled.footer`
  position: relative;
  background-color: var(--blackish);
  padding: 5rem 0px;
  color: var(--whiteish);
  & .footer-content {
    display: flex;
    flex-direction: column;
    & .footer-right {
      padding-top: 3rem;
      display: flex;
      width: 100%;
      justify-content: space-between;

      & .footer-contact {
        & li {
          display: flex;
          align-items: center;
          margin-bottom: 0.75rem;
          &:last-child {
            margin-bottom: 0;
          }
          & img {
            width: 25px;
            height: auto;
          }
          & p,
          a {
            padding-left: 1rem;
          }
        }
      }
      & .footer-menu {
        display: flex;
        flex-direction: column;
        & li {
          margin-bottom: 0.75rem;
          &:last-child {
            margin-bottom: 0;
          }
          & a {
            position: relative;
            display: inline-block;
            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: var(--pale-blue);
              width: 0;
              height: 1px;
              opacity: 0;
              transition: 0.3s cubic-bezier(0.5, 0.25, 0, 1);
            }
            &:hover:not(.active) {
              color: var(--pale-blue);
              &:after {
                opacity: 1;
                width: 100%;
              }
            }
            &.active {
              color: var(--blue);
              cursor: default;
            }
          }
        }
      }
    }

    ${media.md`
      flex-direction: row;
      justify-content: space-between;

      & .footer-right {
        padding-top: 0;
        width: 50%;
      }
    `}
  }
`;

export default FooterStyles;
