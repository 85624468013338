import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "../../common/button/Button";
import FormStyles from "./FormStyles";

const Form = () => {
  const [mailSent, setMailSent] = useState(false);
  const [sentError, setSentError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [inputErrors, setInputErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [sentBtnDisable, setSentBtnDisable] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [send, setSend] = useState(false);

  useEffect(() => {
    if (send && formValid) {
      async function post() {
        setSentBtnDisable(true);
        try {
          const response = await axios.post(
            "mail.php",
            JSON.stringify(formData)
          );
          setSend(false);
          setSentBtnDisable(false);
          if (response.data.status === "ok") {
            setFormData({
              name: "",
              email: "",
              message: "",
            });
            setMailSent(true);
          }
          if (response.data.errors) {
            setSentError(true);
          }
        } catch (error) {
          setSentError(true);
          setSentBtnDisable(false);
        }
      };
      post();
    }
  }, [send, formValid,formData]);

  

  const handleChange = (event) => {
    const stripHTMLValue = event.target.value.replace(/(<([^>]+)>)/gi, "");
    setFormData({
      ...formData,
      [event.target.name]: stripHTMLValue,
    });
  };

  const handleValidation = () => {
    let fields = formData;
    let errors = {};

    // Name validation
    if (!fields["name"]) {
      errors["name"] = "Pole nie może pozostać puste!";
    } else if (!fields["name"].match(/^[\s\p{L}]+$/u)) {
      errors["name"] = "Niepoprawny format!";
    }

    // Email validation

    if (!fields["email"]) {
      errors["email"] = "Pole nie może pozostać puste!";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        errors["email"] = "Niepoprawny adres email!";
      }
    }

    // Message validation
    if (!fields["message"]) {
      errors["message"] = "Pole nie może pozostać puste!";
    }

    setInputErrors(errors);

    if (!Object.values(errors).length) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSend(true);
    handleValidation();
  };

  const getStyle = (property) =>
    formData[property] && !inputErrors[property]
      ? "valid"
      : inputErrors[property]
      ? "no-valid"
      : formData[property]
      ? "not-empty"
      : null;

  let fieldNameStyles = getStyle("name");

  let fieldEmailStyles = getStyle("email");

  let fieldMessageStyles = getStyle("message");

  return (
    <FormStyles statusVisible={mailSent || sentError}>
      <form action="#" noValidate>
        <h3>Formularz kontaktowy</h3>
        <div className="sent-status">
          {mailSent && <p className="success">Wiadomość została wysłana.</p>}
          {sentError && (
            <p className="error">Wystąpił błąd podczas wysyłania wiadomości!</p>
          )}
        </div>
        <fieldset className={fieldNameStyles}>
          {inputErrors.name && (
            <div className="input-error">{inputErrors.name}</div>
          )}
          <div className="field">
            <input
              type="text"
              name="name"
              required
              id="name"
              maxLength="50"
              value={formData["name"]}
              onChange={(event) => handleChange(event)}
            />
            <label htmlFor="name">Imię i nazwisko</label>
            <span className="focus-border">
              <i></i>
            </span>
          </div>
        </fieldset>
        <fieldset className={fieldEmailStyles}>
          {inputErrors.email && (
            <div className="input-error">{inputErrors.email}</div>
          )}
          <div className="field">
            <input
              type="email"
              name="email"
              required
              id="email"
              value={formData["email"]}
              onChange={(event) => handleChange(event)}
            />
            <label htmlFor="email">Adres email</label>
            <span className="focus-border">
              <i></i>
            </span>
          </div>
        </fieldset>
        <fieldset className={fieldMessageStyles}>
          {inputErrors.message && (
            <div className="input-error">{inputErrors.message}</div>
          )}
          <div className="field">
            <textarea
              name="message"
              required
              id="message"
              rows="8"
              cols="70"
              value={formData["message"]}
              onChange={(event) => handleChange(event)}
            />
            <label htmlFor="message">Treść wiadomości</label>
            <span className="focus-border">
              <i></i>
            </span>
          </div>
        </fieldset>
        <fieldset>
          <span className="honey-row">
            <label htmlFor="honey">Do not fill out this field</label>
            <input id="honey" name="honey" type="text" autoComplete="none" />
          </span>
          <Button
            type="submit"
            clickHandler={handleFormSubmit}
            disabled={sentBtnDisable}
          >
            wyślij
          </Button>
        </fieldset>
      </form>
    </FormStyles>
  );
};

export default Form;
