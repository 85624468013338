import React from "react";
import { string } from "prop-types";
import SlideStyles from "./SlideStyles";

const Slide = ({ imgsm, imgmd, imglg, imgxl, title, subtitle }) => {
  return (
    <SlideStyles
      imgsm={imgsm}
      imgmd={imgmd}
      imglg={imglg}
      imgxl={imgxl}
      title={title}
      subtitle={subtitle}
    >
      <div className="slide-header">
        <h1 className="slide-title">{title}</h1>
        <p className="slide-subtitle">{subtitle}</p>
      </div>
    </SlideStyles>
  );
};

Slide.propTyps = {
  imgXS: string.isRequired,
  imgSM: string.isRequired,
  imgMD: string.isRequired,
  imgLG: string.isRequired,
  imgXL: string.isRequired,
  title: string,
  subtitle: string,
};

Slide.defaultProps = {
  title: "",
  subtitle: "",
};

export default Slide;
