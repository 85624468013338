import React from "react";
import HeaderBgImage from "../common/headerBgImage/HeaderBgImage";
import Container from "../common/styledComponents/Container";
import SectionContent from "../common/styledComponents/SectionContent";
import Text from "../common/styledComponents/Text";
import TextWrapper from "../common/styledComponents/TextWrapper";
import Title from "../common/styledComponents/Title";
import Footer from "../footer/Footer";
import AboutStyles from "./AboutStyles.js";

const About = ({ aboutData }) => {
  return (
    <>
      <AboutStyles>
        <HeaderBgImage
          imgSrcset={aboutData.imgSrcset}
          imgFallback={aboutData.imgFallback}
          withShadow
          withTitle
        >
          <Title withDecoration light>
            o kancelarii
          </Title>
        </HeaderBgImage>
        <Container>
          <SectionContent>
            <TextWrapper>
              <Text>{aboutData.description}</Text>
              <Text>{aboutData.workingHours}</Text>
            </TextWrapper>
          </SectionContent>
        </Container>

        <div className="team">
          <Container>
            <Title light marginBottom>
              notariusze
            </Title>
            <ul>
              {aboutData.team.map((empl) => (
                <li key={empl.name}>
                  <span>
                    <img src={empl.img} alt="Notariusz" />
                  </span>
                  <h3>{empl.name}</h3>
                  <p>{empl.text}</p>
                </li>
              ))}
            </ul>
          </Container>
        </div>
      </AboutStyles>
      <Footer />
    </>
  );
};

export default About;
