import styled from "styled-components";
import { media } from "../../theme/globalStyle";

const HomeStyles = styled.section`
  .slick-slider .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide img {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: none;
    -o-transform: translate3d(0, 0, 0);
    transform: none;
  }
  .slick-dots {
    width: 100vw;
    position: absolute;
    bottom: 2rem;
    text-align: center;
    & li {
      width: 0.75rem;
      height: 0.75rem;
      border: 2px solid var(--blue);
      border-radius: 50%;
      margin-right: 0.75rem;
      display: inline-block;
      cursor: pointer;
      /* & > button {
        display: none;
      } */
      &:last-of-type {
        margin-right: 0;
      }
      &.slick-active {
        border: 3px solid var(--blue);
        background-color: var(--blue);
      }
    }
  }
  & .home-services {
    position: relative;
    display: flex;
    background-color: var(--blackish);
    background-image: ${(props) => props.imgsm && `url("${props.imgsm}")`};
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;

    ${media.md`background-image: ${(props) =>
      props.imgmd && `url("${props.imgmd}")`};`}
    ${media.lg`background-image: ${(props) =>
      props.imglg && `url("${props.imglg}")`};`}
    ${media.xl`background-image: ${(props) =>
      props.imgxl && `url("${props.imgxl}")`};`}

& .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      background-attachment: fixed;
    }

    & .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 5rem 0;
      z-index: 2;

      & .title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      & ul {
        padding-bottom: 3rem;
        display: flex;
        flex-direction: column;

        & li {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: baseline;
          font-size: 1.0625rem;
          line-height: 1.7;
          font-weight: 500;
          padding-bottom: 1.0625rem;
          letter-spacing: 1px;
          color: var(--whiteish);

          & span {
            display: flex;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--dark-blue);
            margin-right: 0.75rem;
          }
        }
      }

      ${media.lg`
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        & h2{
          margin-bottom: 1rem;
        }

        & ul {
          align-items: flex-start;
          max-width: 50%;
          padding-bottom: 0;
          
          & li {
            text-align: left;
          }
        }
      `}
    }
  }
`;

export default HomeStyles;
